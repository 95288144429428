.start {
   display: grid;
   align-items: center;
   justify-items: center;
   
   &__prompt {
      font-size: 1.25em;
      font-weight: bold;
      line-height: 1.4em;
      margin-bottom: 1rem;
   }
}