.form {
   &_layout-grid {
      padding: 1rem;
      width: 100%;
      display: grid;
      grid-gap: 1rem;
      gap: 1rem;
   }

   &_color-default {
      background: #eaeaea;
   }

   &__field {
      display: grid;
      grid-gap: 0.25rem;
      gap: 0.25rem;
   }
}