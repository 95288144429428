.title-group {
   display: grid;
   grid-gap: 0.75rem 0.5rem;
   gap: 0.75rem 0.5rem;
   align-items: center;
   grid-template-columns: auto 1fr;

   &__big {
      grid-column: 1 / 3;
   }

   &__small {
      text-transform: uppercase;
      letter-spacing: 0.01em;
      margin-bottom: 0.01em;
   }

   &__icon {
      grid-row: 2;
      fill: currentColor;

      &--easy {
         color: $puzzle_color_easy;
      }

      &--medium {
         color: $puzzle_color_medium;
      }

      &--hard {
         color: $puzzle_color_hard;
      }

      &--expert {
         color: $puzzle_color_expert;
      }
   }
}

.page-title {
   color: #454545;
   font-size: 1.5em;
   font-weight: bold;
   line-height: 1.1em;
   text-transform: uppercase;
   letter-spacing: 0.01em;
}

.section-title {
   color: #333333;
   font-size: 1.125em;
   font-weight: bold;
   line-height: 1.3em;
}

.body-copy {
   display: grid;
   grid-gap: $basis;
   gap: $basis;
   font-size: 1em;
   line-height: 1.5em;
   max-width: 72ch;
}

.small {
   font-size: 0.9em;
   line-height: 1.5em;
   max-width: 72ch;
}