.puzzle-list {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
   grid-gap: 2rem;
   gap: 2rem;

   @media (min-width: 1100px) {
      grid-template-columns: repeat(4, 1fr);
   }

   &__item {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto 1fr;
      align-items: start;
      grid-gap: 0.5rem 0.75rem;
      gap: 0.5rem 0.75rem;
   }

   &__icon {
      grid-row: 1 / 3;
      fill: currentColor;

      &--easy {
         color: $puzzle_color_easy;
      }

      &--medium {
         color: $puzzle_color_medium;
      }

      &--hard {
         color: $puzzle_color_hard;
      }

      &--expert {
         color: $puzzle_color_expert;
      }

      &--inprogress {
         color: $puzzle_color_inprogress;
      }

      &--completed {
         color: $puzzle_color_completed;
      }

      &--default {
         color: $puzzle_color_default;
      }

   }

   &__date {      
      color: #454545;
      font-size: 0.9em;
      line-height: 1em;
      text-transform: uppercase;
      letter-spacing: 0.01em;
   }
}