.validation {
   display: grid;

   &_style-row {
      justify-self: start;
      align-items: center;
      grid-auto-flow: column;

      .validation__button {
         display: flex;
      }

      .validation__square {
         border-top: $border;
         border-bottom: $border;
         border-left: $border;

         &:last-child {
            border-right-width: 1px;
            border-right-style: solid;
         }
      }

      .validation__tip {
         margin-left: 0.25rem;
      }
   }

   &_style-col {
      align-self: start;
      justify-items: center;

      .validation__square {
         border-top: $border;
         border-right: $border;
         border-left: $border;

         &:last-child {
            border-bottom-width: 1px;
            border-bottom-style: solid;
         }
      }

      .validation__tip {
         margin-top: 0.25rem;
      }
   }

   &_style-sub {
      align-items: start;
      grid-auto-flow: column;

      .validation__button {
         padding: 0;
         display: grid;
         grid-template-columns: repeat(3, 1fr);
         grid-template-rows: repeat(3, 1fr);
      }

      .validation__square {
         border-top: $border;
         border-left: $border;

         &:nth-child(3n) {
            border-right-width: 1px;
            border-right-style: solid;
         }

         &:nth-last-child(-n+3) {
            border-bottom-width: 1px;
            border-bottom-style: solid;
         }
      }

      .validation__tip {
         margin-left: 0.75rem;
      }
   }

   &_color-default {
      .validation__square {
         border-color: #7a7a7a;

         &--current {
            background: #ccc;
         }
      }
   }

   &_color-visible {
      .validation__square {
         border-color: #7f95aa;
         background: #DAEDFF;

         &--current {
            background: #A7BED3;
         }
      }

      .validation__tip {
         color: #1a2733;
         border-color: #a0b9d1;
         background: #DAEDFF;
         box-shadow: 0 2px 8px 0 rgba(99, 133, 163, 0.5);
      }
   }

   &_color-error {
      .validation__square {
         border-color: #b6a15e;
         background: #FFFBA1;

         &--current {
            background: #dfcb8b;
         } 
      }

      .validation__tip {
         color: #301D00;
         border-color: #dac37b;
         background: #FFFBA1;
         box-shadow: 0 2px 8px 0 rgba(221, 196, 116, 0.7);
      }
   }

   &_color-complete {
      .validation__square {
         border-color: #89b183;
         background: #D1FCCE;

         &--current {
            background: #ABD2A7;
         } 
      }
   }

   &__button {
      cursor: pointer;
      background: none;
      border: none;
      padding: 0.5rem;
   }

   &__square {
      width: 1rem;
      height: 1rem;
   }

   &__tip {
      display: grid;
      font-size: 0.9em;
      font-weight: bold;
      grid-gap: 0.2rem;
      gap: 0.2rem;

      border: 1px solid transparent;
      padding: 0.4rem 0.5rem 0.5rem;

      &--horizontal {
         grid-auto-flow: column;
      }

      &--vertical {
         
      }
   }
}