$primary_color: #0066ff;

$error_color: #724848;
$error_bg_color: #ffe7e7;
$error_border_color: #dfc6c6;
$error_outline-color: #dfc6c6;

$success_color: #1e4118;
$success_bg_color: #8ecc85;
$success_border_color: #77b66d;

$entry_color_default: #333333;
$entry_color_guess: #1160c2;

$puzzle_color_easy: #8cd33b;
$puzzle_color_medium: #e9cd32;
$puzzle_color_hard: #f09c3c;
$puzzle_color_expert: #e04834;
$puzzle_color_inprogress: #7cb0cc;
$puzzle_color_completed: #8ecc85;
$puzzle_color_default: #5b78a5