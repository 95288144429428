@mixin labelDefault() {
   top: 0.5rem;
   text-transform: uppercase;
   letter-spacing: 0.01em;
   font-size: 0.9em;
   transform: translateY(0);

   .floating-field_color-default & {
      color: #666666;
   }

   .floating-field_color-error & {
      color: #5e4747;
   }
}

.floating-field {
   position: relative;
   padding: 0.5rem;
   border: 1px solid;
   
   width: 100%;
   height: 4rem;

   &_color-default {
      border-color: #cccccc;
      background: #eaeaea;

      .floating-field__togglePassword {
         color: #464646;
      }

      .floating-field__input {
         outline-color: #cccccc;
      }
   }

   &_color-error {
      border-color: $error_border_color;
      background: $error_bg_color;

      .floating-field__togglePassword {
         color: $error_color;
      }

      .floating-field__input {
         outline-color: $error_outline_color;
      }
   }

   &:focus-within {
      padding-left: 0.5rem;

      .floating-field__label {
         @include labelDefault();
      }

      .floating-field__togglePassword {
         display: flex;
      }
   }
   
   &--inactive {
      padding-left: 1rem;

      .floating-field__label {
         top: 50%;
         transform: translateY(-60%);
         font-size: 1.125em;
         text-transform: unset;

         .floating-field_color-default & {
            color: #333;
         }

         .floating-field_color-error & {
            color: #3d2d2d;
         }
      }

      .floating-field__togglePassword {
         display: none;
      }
   }

   &__label {
      position: absolute;
      line-height: 1em;
      transition: all 0.25s ease-in-out;

      @include labelDefault();
   }

   &__input {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
      height: 100%;
      padding: 2rem 0.5rem 1rem;
      background: transparent;
      border: none;
      font-family: inherit;
      font-size: 1.125em;
      line-height: 1em;

      &:focus {
         outline-width: 4px;
         outline-style: solid;
      }
   }

   &__error {
      position: absolute;
      top: 0.45rem;
      right: 0.75rem;
      color: #3d2d2d;
      font-size: 0.9em;
      font-style: italic;
      text-align: right;
   }

   &__togglePassword {
      position: absolute;
      right: 0.75rem;
      bottom: 0.5rem;

      width: 1.5rem;
      height: 1.5rem;

      background: none;
      padding: 0;
      border: none;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      &-icon {
         fill: currentColor;
      }
   }
}