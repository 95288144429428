.view-puzzle {
   display: grid;
   grid-template-columns: 320px 1fr 260px 0.25fr;
   grid-gap: 2rem;
   gap: 2rem;
   align-items: start;

   &__subgrids {
      height: 580px;
      display: grid;
      align-items: start;
      justify-items: start;
      grid-gap: 1.5rem;
      gap: 1.5rem;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
   }

   &__main {
      display: grid;
      grid-template-columns: repeat(9, 3rem ) 1fr;
      grid-template-rows: repeat(2, 3rem) calc(3rem + 2px) repeat(2, 3rem) calc(3rem + 2px) repeat(2, 3rem) 1fr;

      align-items: center;
      justify-items: center;
   }

   &__board {
      grid-column: 1 / 10;
      grid-row: 1 / 10;

      align-self: start;
      justify-self: start;
   }

   &__actions {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto 1fr;
      grid-gap: 1rem;
      gap: 1rem;
      justify-items: start;
   }

   &__mark-mode {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      align-items: center;
   }

   &__mark-button {
      border: none;
      padding: 0.25rem 1rem 0.5rem;
      text-align: center;
      transition: all 0.2s ease-in-out;
      font-size: 1.5em;
      line-height: 1em;
      color: #fff;

      &--default {
         background: $entry_color_default;
      }

      &--guess {
         background: $entry_color_guess;
      }

      &--press {
         cursor: pointer;
      }
   }

   &__toggle-tips {
      display: grid;
   }
}