.link {
   font-family: inherit;
   font-size: 1em;
   line-height: 1.3em;

   &_style-text {
      color: $primary_color;
   }

   &_style-solid {
      padding: 0.5rem 0.7rem 0.6rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #ffffff;
      background: $primary_color;
   }

   &_style-outline {
      padding: 0.8rem 0.8rem 0.9rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: bold;
      color: $primary_color;
      border: 3px solid $primary_color;
   }
}