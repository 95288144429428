.loading {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-gap: 0.5rem;
   gap: 0.5rem;
   margin-bottom: 1rem;

   &__square {
      width: 1.5rem;
      height: 1.5rem;
      background: rgba(0, 102, 255, .1);
      animation: color 1.8s ease-in-out reverse infinite;

      &--1 {
         animation-delay: 0s;
      }

      &--2 {
         animation-delay: 0.2s;
      }

      &--3 {
         animation-delay: 0.4s;
      }

      &--4 {
         animation-delay: 0.6s;
      }

      &--5 {
         animation-delay: 0.8s;
      }

      &--6 {
         animation-delay: 1s;
      }

      &--7 {
         animation-delay: 1.2s;
      }

      &--8 {
         animation-delay: 1.4s;
      }

      &--9 {
         animation-delay: 1.6s;
      }
   }

   @keyframes color {
      from {
         background: rgba(0, 102, 255, .1);
      }
      to {
         background: rgba(0, 102, 255, 1);
      }
   }
}