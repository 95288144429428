.create-puzzle {
   display: grid;
   grid-gap: 2rem;
   gap: 2rem;
   justify-items: start;
   align-items: start;

   @media (min-width: 500px) and (max-width: 949px) {
      grid-template-columns: 16rem 1fr;
      grid-template-rows: auto auto 1fr;
      
      &__fields {
         grid-row: 1 / 3;
      }

      &__board {
         grid-row: 3;
      }

      &__errors {
         grid-row: 2;
         grid-column: 2;
      }
   }

   @media (min-width: 950px) {

      grid-template-columns: 16rem auto 1fr;
      grid-template-rows: auto 1fr;
      
      &__fields,
      &__board {
         grid-row: 1 / -1;
      }

      &__errors {
         grid-row: 2;
         grid-column: 3;
      }
   }
}