$basis: 24px;

.vertical-rhythm-1 {
   margin-bottom: $basis;
}

.vertical-rhythm-2 {
   margin-bottom: $basis * 2;
}

.vertical-rhythm-3 {
   margin-bottom: $basis * 3;
}