.main {
   padding: $basis $basis $basis*2;
   flex-grow: 1;

   display: flex;
   align-items: center;
   justify-content: center;

   &--public {
      background: #666666 0% center repeat;
      
      animation: drift 180s linear infinite;

      @keyframes drift {
         to {
            background-position: 0% 0%;
         }
         from {
            background-position: -100% 100%;
         }
      }
   }

   &_theme-homepage {
      background: #1d1d1d;
      color: #ccc;
   }
}