.prompt {
   width: 100%;
   max-width: 360px;
   display: grid;
   justify-items: center;
   grid-gap: 1.5rem;
   gap: 1.5rem;
   background: #ffffff;
   padding: 2rem;
   border-radius: 1rem;
   box-shadow: 0 0 2rem 0 rgba(0,0,0,0.75);

   &__brand {
      display: flex;
      align-items: center;
      justify-content: center;
   }

   &__form {
      width: 100%;
      display: grid;
      grid-gap: 1.5rem;
      gap: 1.5rem;
      justify-items: center;
   }

   &__fields {
      width: 100%;
      display: grid;
      grid-gap: 0.75rem;
      gap: 0.75rem;
   }
}