.color-picker {
   position: relative;
   display: flex;
   align-items: flex-start;

   &__button {
      cursor: pointer;
      padding: 0.25rem;
      margin-right: 1rem;
   }

   &__sample {
      width: 2rem;
      height: 2rem;
   }

   &__modal {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 4rem;

      &-style {
         width: 300px;
         height: calc(50px + (2 * 1rem));
         padding: 1rem;
         background: #ffffff;
         box-shadow: 0 0 10px 0 rgba(51,51,51,0.5);
         border-radius: 0.25rem;
      }
   }
}