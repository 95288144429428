.slidein {
   position: fixed;
   top: 6rem;
   right: 2rem;
   box-shadow: 0 2px 8px 0 rgba(121,121,121,0.5);

   // default settings
   opacity: 0;
   transform: translateX(100%);

   // animate in
   transition: all 0.3s ease-in-out;

   &-show {
      opacity: 1;
      transform: translateX(0);

      // animate out
      transition: all 0.4s ease-in-out;
   }

   &__dismiss {
      position: absolute;
      top: -0.75rem;
      right: -0.75rem;
      cursor: pointer;
      border: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      background: #454545;
      border-radius: 1rem;
   }

   &__icon {
      width: 1rem;
      height: 1rem;
      color: #eaeaea;
      fill: currentColor;
   }
}