.transition {
   display: grid;
   grid-template-rows: 1fr;
   grid-template-columns: 1fr;
   align-items: center;
   justify-content: center;
   width: 100%;
   overflow: hidden;
   position: relative;

   &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem;
   }

}

.fade {
   &-enter {
      opacity: 0;
      
      &-active {
         opacity: 1;
         transition: opacity 0.3s ease-in-out;
      }
   }

   &-exit {
      opacity: 1;

      &-active {
         opacity: 0;
         transition: opacity 0.3s ease-in-out;
      }
   }
}

.swipe {
   &-enter {
      opacity: 0;
      
      &-active {
         opacity: 1;
         transition: all 0.25s ease-in-out 0.15s;
         animation: move 0.4s ease-out reverse;
      }
   }

   &-exit {
      opacity: 1;

      &-active {
         opacity: 0;
         transition: opacity 0.25s ease-in-out 0.15s;
         animation: move 0.4s ease-in;
      }
   }

   @keyframes move {
      0% {
         margin-left: 0;
      }
      15% {
         margin-left: -5%;
      }
      20% {
         margin-left: -5%;
      }
      100% {
         margin-left: 100%;
      }
   }
}