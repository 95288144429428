.indicator {
   position: relative;
   width: 4rem;
   height: 2rem;
   background: #cccccc;
   background: radial-gradient(ellipse, #ffffff 50%, #b6b6b6 100%); 
   box-shadow: 0 0 5px 1px #dddddd inset;
   border-radius: 1rem;
   overflow: hidden;

   &--start {
      .indicator__button {
         left: 0;
         top: 0;
      }
   }

   &--end {
      .indicator__button {
         left: calc(100% - 2rem);
         top: calc(100% - 2rem);
      }
   }

   &__button {
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      position: absolute;
      background: #0066ff;
      background: radial-gradient(circle at 70% 25%, #3787ff 0%, #0066ff 30%, #004ab9 100%); 
      box-shadow: -1px 1px 5px 0 rgba(0, 36, 90, 0.8);
      border: none;
      padding: 0;
      width: 2rem;
      height: 2rem;
      border-radius: 2rem;
   }
}