.button {
   cursor: pointer;
   background: transparent;
   border: none;
   padding: 0;
   font-family: inherit;
   font-size: 1em;
   line-height: 1em;

   &__layout {
      display: grid;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      align-items: center;
      justify-items: start;

      &--icon-left {
         grid-template-columns: auto 1fr;
      }

      &--icon-right {
         grid-template-columns: 1fr auto;
      }
      
   }

   &_style-solid {
      padding: 0.7rem 0.9rem 0.9rem;
      text-transform: uppercase;
      letter-spacing: 1px;

      &--primary {
         color: #ffffff;
         background: $primary_color;
      }

      &--default {
         color: #333333;
         background: #cccccc;
      }
   }

   &_style-outline {
      padding: 0.8rem 0.8rem 0.9rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: bold;
      color: $primary_color;
      border: 3px solid $primary_color;
   }

   &--disabled {
      background: #ebebeb;
      color: #9e9e9e;
   }

   &__icon {
      fill: currentColor;
   }

   &__text {
      margin-bottom: 0.02em;
   }
}